<template>
  <v-app-bar class="appBar" dense app elevation="0">
    <v-app-bar-nav-icon @click="toggleDrawer()">
      <v-icon>mdi-menu</v-icon>
    </v-app-bar-nav-icon>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>
<script>
//import { bus } from "../main";

export default {
  name: "GroupListAppBar",
  data: () => ({}),
  methods: {
    toggleDrawer() {
      this.$store.commit("toggleDrawer", ["docs", true]);
    },
  },
};
</script>
